const jQuery = require('jquery');
window.$ = window.jQuery = jQuery;

import Popper from 'popper.js/dist/umd/popper.js';

//require('jquery-aniview');

import AOS from 'aos';

AOS.init({
    once: true,
    useClassNames: true,
    initClassName: false,
});


//
// var options = {
//     animateThreshold: 70,
//     scrollPollInterval: 20
// }
//
//
// $('.aniview').AniView(options);

// Toggle navigation
$('.js-hamburger').click(function () {
    $(this).toggleClass('is-active');
    var width = checkWidth();
    var calcWidth = (width - 1230) / 2;
    var menuWidth;

    if (width <= 600) {
        menuWidth = 265;
    }else{
        menuWidth = 400;
    }

    if (calcWidth > 0) {
        $('aside').css({"width": calcWidth + (menuWidth + 1)  });
    }

    if ($('body').hasClass('show-nav')) {
        $('body').removeClass('show-nav');
        $('aside').css({"width": menuWidth });
    } else {
        $('body').addClass('show-nav');
    }
});



$('.js-toggle-menu').click(function () {

    $(this).toggleClass('active').closest('li').find('ul').slideToggle();
});



$( window ).resize(function() {

    var width = checkWidth();
    var calcWidth = (width - 1230) / 2;

    if(width > 1230) {

        if ($('body').hasClass('show-nav')) {
            $('aside').css({"width": calcWidth + 400 });
        }

    }
});



function checkWidth() {
    var width = $(document).width();
    return width;

}



var ref = $('.dropdown-toggle');
var popover = $('.dropdown-menu');
popover.hide();



$(document).on('click', function (e) {
    var target = $(e.target);
    if (target.is(ref) || target.is(popover)) {
        e.preventDefault();
        popover.show();
        var popper = new Popper(ref, popover, {
            placement: 'bottom-start',
        });
    } else {
        popover.hide();
    }
});




var searchActive = false;

$('.js-search').on('click', function () {

    $('.search-container').addClass('is-active');
    $('.input-search').focus();
    searchActive = true;
});



$('.c-btn--close').on('click', function () {
    $('.search-container').removeClass('is-active');
    searchActive = false;
});



$(document).mouseup(function (e) {
    var container = $(".search-container");

    if (searchActive == true) {
        if (!container.is(e.target) && container.has(e.target).length === 0) {
            $('.search-container').removeClass('is-active');
            searchActive = false;
        }
    }
});



$('[data-scroll]').on('click', function () {
    var id = $(this).attr('data-scroll');
    var offset = $(this).attr('data-offset');

    goToByScroll(id, offset);
    return false;
});


var offset = 300,
    offset_opacity = 1200,
    scroll_top_duration = 700,
    $back_to_top = $('.cd-top');


$(window).scroll(function () {
    ($(this).scrollTop() > offset) ? $back_to_top.addClass('cd-is-visible') : $back_to_top.removeClass('cd-is-visible cd-fade-out');
    if ($(this).scrollTop() > offset_opacity) {
        $back_to_top.addClass('cd-fade-out');
    }
});

$back_to_top.on('click', function (event) {
    event.preventDefault();
    $('body,html').animate({
            scrollTop: 0,
        }, scroll_top_duration
    );
});


$('.js-to-top').click(function () {
    $('body,html').animate({
        scrollTop: 0
    }, 500);
});


function goToByScroll(id, offset) {
    $("html,body").animate({
        scrollTop: $("#" + id).offset().top - offset
    }, "slow");
}


